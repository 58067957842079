import Link from "next/link";

import PodcastPageIcon from "@/icons/podcast-site.svg";
import EpisodePageIcon from "@/icons/episode-site.svg";
import Typography from "@/components/Typography";
import { applicationRoutes } from "@/helpers/applicationRouting";

import { InternalLinkInterface } from "../ContextualMenu.interface";
import styles from "../ContextualMenu.module.scss";

const InternalLink: React.FC<InternalLinkInterface> = ({ slug, variant }) => {
  const isPodcast = variant === "podcast";
  const pageLink = isPodcast
    ? applicationRoutes.PODCAST + slug
    : applicationRoutes.PODCAST_EPISODE + slug;

  return (
    <button className={styles.button}>
      {isPodcast ? <PodcastPageIcon /> : <EpisodePageIcon />}
      <Typography variant="medium" component="span">
        <Link href={pageLink}>
          <a className={styles.link}>
            {isPodcast
              ? "Przejdź do strony podcastu"
              : "Przejdź do strony epizodu"}
          </a>
        </Link>
      </Typography>
    </button>
  );
};

export default InternalLink;
