import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import truncate from "lodash/truncate";
import { useStoreState } from "pullstate";

import CloseIcon from "@/icons/close-small.svg";
import FacebookIcon from "@/icons/social/fb.svg";
import MessengerIcon from "@/icons/social/messenger.svg";
import TwitterIcon from "@/icons/social/twitter.svg";
import CopyIcon from "@/icons/social/copy.svg";
import InstaIcon from "@/icons/social/author-insta.svg";
import BaseModal from "@/components/Modals/BaseModal";
import Typography from "@/components/Typography";
import { createSticker } from "@/helpers/createSticker";
import Spinner from "@/components/Spinner";
import { PlayerStore } from "@/store/PlayerStore";

import { ShareModalInterface } from "../Modal.interface";

import styles from "./ShareModal.module.scss";

enum CopyText {
  Copy = "Kopiuj link",
  Copied = "Skopiowano link",
}

export const ShareModal: React.FC<ShareModalInterface> = ({
  artwork: image,
  title,
  subtitle,
  onClose,
  isOpen,
  link: shareableLink,
  isStoriesShareAvailable = false,
}) => {
  const { artwork: storeImage } = useStoreState(PlayerStore, (store) => store);
  const artwork = image || storeImage;
  const [isStickerLoading, setStickerLoading] = useState<boolean>(false);
  const route = useRouter();
  const copySource = useRef(null);
  const isClientSide = typeof window !== "undefined";
  const [copyText, setCopyText] = useState<CopyText>(CopyText.Copy);
  const domain = process.env.NEXT_PUBLIC_DOMAIN;
  const fbApiKey = process.env.NEXT_PUBLIC_FACEBOOK_API_KEY;
  const link = shareableLink || domain + route.asPath;
  const facebookSharer = "https://www.facebook.com/sharer/sharer.php?u=";
  const twitterSharer = "https://twitter.com/intent/tweet?text=";
  const fbShareLink = facebookSharer + link;
  const twitterShareLink = twitterSharer + link;
  const messengerLink =
    "fb-messenger://share/?link=" +
    link +
    "&app_id=" +
    encodeURIComponent(fbApiKey);
  const isMobileApp = process.env.NEXT_PUBLIC_MOBILE_APP === "true";
  const isInstaShare =
    isMobileApp &&
    isClientSide &&
    window.RNisInstaShare &&
    isStoriesShareAvailable &&
    artwork;

  const copyToClipboard = () => {
    // Android
    copySource.current.focus();
    copySource.current.select();
    document.execCommand("copy");
    copySource.current.blur();

    // Desktop and iOS
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
    }

    setCopyText(CopyText.Copied);
  };

  const shareOnStories = (image) => {
    setStickerLoading(false);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "shareOnStories",
          image,
          label: `${title} - ${subtitle}`,
        })
      );
    }
  };

  const getSticker = () => {
    setStickerLoading(true);
    const shortenTitle = truncate(title, { length: 46, separator: " " });
    const shortenSubTitle = truncate(subtitle, { length: 32, separator: " " });
    createSticker(artwork, shortenTitle, shortenSubTitle, shareOnStories);
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.shareModal}
      variant="secondary"
    >
      <div className={styles.shareBox}>
        <div className={styles.heading}>
          <Typography variant="h4">Udostępnij</Typography>

          <button className={styles.close} onClick={onClose}>
            <CloseIcon className={styles.closeIcon} />
          </button>
        </div>

        <div className={styles.actions}>
          <a
            href={fbShareLink}
            className={styles.shareButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={styles.socialIcon} /> Facebook
          </a>

          <a
            href={twitterShareLink}
            className={styles.shareButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className={styles.socialIcon} /> Twitter
          </a>

          <a
            href={messengerLink}
            className={classNames(styles.shareButton, styles.messenger)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MessengerIcon className={styles.socialIcon} /> Messenger
          </a>

          <button
            className={classNames(
              styles.shareButton,
              styles.copyLink,
              !isInstaShare && styles.full
            )}
            onClick={copyToClipboard}
          >
            <CopyIcon className={styles.socialIcon} /> {copyText}
          </button>

          <textarea
            contentEditable
            readOnly={false}
            ref={copySource}
            value={isClientSide && link}
            className={styles.copySource}
          />

          {isInstaShare && (
            <button
              className={classNames(styles.shareButton, styles.insta)}
              onClick={getSticker}
            >
              <InstaIcon className={styles.socialIcon} /> Stories
              {isStickerLoading && <Spinner className={styles.spinner} />}
            </button>
          )}
        </div>
      </div>
    </BaseModal>
  );
};

export default ShareModal;
