import { ClassName, SlugType } from "@/helpers/pageHelpers/Common/interface";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";

export enum ContextualMenuEnum {
  Player = "player",
  Feed = "feed",
  Latest = "latest",
}

export interface ContextualMenuInterface {
  isOpen?: boolean;
  source: ContentSourceEnum;
  slug?: SlugType;
  podcast?: { slug: SlugType };
  title: string;
  subtitle: string;
  variant: ContextualMenuEnum;
  className?: ClassName;
  duration: number;
  episodeTime: number;
  artwork?: string;
}

export interface BookmarkInterface {
  source: ContentSourceEnum;
  episodeSlug?: SlugType;
  variant: ContextualMenuEnum;
}

export interface ListenedInterface {
  episodeSlug?: SlugType;
  variant: ContextualMenuEnum;
  duration: number;
  episodeTime: number;
}

export interface InternalLinkInterface {
  slug: SlugType;
  variant: "podcast" | "episode";
}
