import { useStoreState } from "pullstate";
import classnames from "classnames";

import EmptyBookmarkIcon from "@/icons/empty-bookmark.svg";
import BookmarkIcon from "@/icons/rounded-bookmark.svg";
import { PlayerStore } from "@/store/PlayerStore";
import { useBookmark } from "@/helpers/useBookmark";
import Typography from "@/components/Typography";

import styles from "../ContextualMenu.module.scss";
import {
  BookmarkInterface,
  ContextualMenuEnum,
} from "../ContextualMenu.interface";

const Bookmark: React.FC<BookmarkInterface> = ({
  source,
  episodeSlug,
  variant,
}) => {
  const { slug } = useStoreState(PlayerStore, (store) => store);
  const bookmarkSlug =
    variant === ContextualMenuEnum.Player ? slug : episodeSlug;
  const { isBookmarked, handleBookmark } = useBookmark(bookmarkSlug, source);

  return (
    <>
      {variant !== ContextualMenuEnum.Latest && (
        <button
          className={classnames(styles.button, "saveForLater")}
          onClick={handleBookmark}
        >
          {isBookmarked ? (
            <>
              <BookmarkIcon />
              <Typography variant="medium" component="span">
                Usuń z zapisanych na później
              </Typography>
            </>
          ) : (
            <>
              <EmptyBookmarkIcon />
              <Typography variant="medium" component="span">
                Zapisz na później
              </Typography>
            </>
          )}
        </button>
      )}
    </>
  );
};

export default Bookmark;
