import React, { useState } from "react";

import styles from "@/components/ContextualMenu/ContextualMenu.module.scss";
import ShareIcon from "@/icons/rounded-share.svg";
import Typography from "@/components/Typography";
import ShareModal from "@/components/Modals/ShareModal";
import { SlugType } from "@/helpers/pageHelpers/Common/interface";
import { routePath } from "@/helpers/routePath";
import { UIStore } from "@/store/UIStore";

export const ShareButton: React.FC<{
  slug: SlugType;
  title: string;
  subtitle: string;
  artwork?: string;
}> = ({ slug, title, subtitle, artwork }) => {
  const [isShareModalOpen, openShareModal] = useState<boolean>(false);
  const shareableLink =
    process.env.NEXT_PUBLIC_DOMAIN +
    routePath.PODCAST_EPISODE.href.replace("[slug]", `${slug}`);

  const openModal = () => {
    openShareModal(true);
    UIStore.update((store) => {
      store.isShareModalOpen = true;
    });
  };

  const closeModal = () => {
    openShareModal(false);
    UIStore.update((store) => {
      store.isShareModalOpen = false;
    });
  };

  return (
    <>
      <button className={styles.button} onClick={openModal}>
        <ShareIcon />
        <Typography variant="medium" component="span">
          Udostępnij
        </Typography>
      </button>
      <ShareModal
        onClose={closeModal}
        isOpen={isShareModalOpen}
        title={title}
        subtitle={subtitle}
        link={shareableLink}
        artwork={artwork}
        isStoriesShareAvailable
      />
    </>
  );
};

export default ShareButton;
