import { useEffect, useState } from "react";
import classnames from "classnames";
import { useStoreState } from "pullstate";

import Typography from "@/components/Typography";
import CheckIcon from "@/icons/checkmark.svg";
import CloseIcon from "@/icons/smallClose.svg";
import { PlayerStore } from "@/store/PlayerStore";
import { UIStore } from "@/store/UIStore";
import { setPosition } from "@/helpers/setPosition";
import { getListenedEpisodes } from "@/helpers/getListenedEpisodes";

import styles from "../ContextualMenu.module.scss";
import {
  ContextualMenuEnum,
  ListenedInterface,
} from "../ContextualMenu.interface";

const Listened = ({
  episodeSlug,
  duration,
  episodeTime,
  variant,
}: ListenedInterface) => {
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const { slug } = useStoreState(PlayerStore, (store) => store);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    setIsCompleted(duration - episodeTime <= 0);
  }, []);

  const changeListenedStatus = async () => {
    setIsCompleted(!isCompleted);
    await setPosition(
      (episodeSlug as string) || slug,
      isCompleted ? 0 : duration
    );
    await getListenedEpisodes();
    if (variant === ContextualMenuEnum.Player || slug === episodeSlug) {
      PlayerStore.update((store) => {
        store.audioCurrentTime = isCompleted ? 0 : duration;
      });
    }
  };

  return (
    <>
      {userLoggedIn && (
        <button
          className={classnames(styles.button)}
          onClick={changeListenedStatus}
        >
          {isCompleted ? (
            <>
              <CloseIcon />
              <Typography variant="medium" component="span">
                Oznacz jako nieodsłuchany
              </Typography>
            </>
          ) : (
            <>
              <CheckIcon />
              <Typography variant="medium" component="span">
                Oznacz jako odsłuchany
              </Typography>
            </>
          )}
        </button>
      )}
    </>
  );
};

export default Listened;
