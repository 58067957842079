export const createSticker = (
  url: string,
  title: string,
  subtitle: string,
  callback: any
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  const portal = process.env.NEXT_PUBLIC_PORTAL;
  const fontFamily =
    portal === "net"
      ? "normal normal 900 36px HelveticaNowDisplayBlk"
      : "italic normal 600 24px urw-din-condensed";

  let sticker = "";

  canvas.height = 640;
  canvas.width = 480;

  ctx.fillStyle = "rgba(255, 255, 255, 0.01)";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  img.crossOrigin = "use-credentials";

  img.onload = () => {
    const height = img.height === img.width ? 480 : img.height * 0.64;
    ctx.drawImage(img, 0, 0, 480, height);
    ctx.strokeStyle = "#000";
    ctx.lineWidth = 2;
    ctx.strokeRect(1, 1, 478, height);
    ctx.font = fontFamily;
    ctx.fillStyle = portal === "net" ? "#000" : "#000";
    ctx.textAlign = "center";
    insertTitle(ctx, title, subtitle, 240, height + 56, 38, 480);
    sticker = canvas.toDataURL("image/png", 1);
    callback(sticker);
  };

  img.src = `${url}?v=420`;
};

const insertTitle = (context, title, subtitle, x, y, lineHeight, fitWidth) => {
  fitWidth = fitWidth || 0;

  if (fitWidth <= 0) {
    context.fillText(title, x, y);
    return;
  }

  let words = title.split(" ");
  let currentLine = 0;
  let idx = 1;

  while (words.length > 0 && idx <= words.length) {
    const str = words.slice(0, idx).join(" ");
    const w = context.measureText(str).width;
    if (w > fitWidth) {
      if (idx == 1) {
        idx = 2;
      }
      context.fillText(
        words.slice(0, idx - 1).join(" "),
        x,
        y + lineHeight * currentLine
      );
      currentLine++;
      words = words.splice(idx - 1);
      idx = 1;
    } else {
      idx++;
    }
  }

  if (idx > 0)
    context.fillText(words.join(" "), x, y + lineHeight * currentLine);

  if (subtitle) {
    const portal = process.env.NEXT_PUBLIC_PORTAL;
    const fontFamily =
      portal === "net"
        ? "normal normal 900 24px HelveticaNowDisplayBlk"
        : "italic normal 600 20px urw-din-condensed";

    context.font = fontFamily;
    context.fillStyle = portal === "net" ? "#000" : "#000";

    context.fillText(subtitle, x, y + lineHeight * (currentLine + 1) + 12);
  }
};
