import classnames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useStoreState } from "pullstate";

import IconButton from "@/components/IconButton";
import Bookmark from "@/components/ContextualMenu/Buttons/Bookmark";
import Listened from "@/components/ContextualMenu/Buttons/Listened";
import Share from "@/components/ContextualMenu/Buttons/Share";
import MoreIcon from "@/icons/more.svg";
import MoreSmallIcon from "@/icons/moreSmall.svg";
import { UIStore } from "@/store/UIStore";

import styles from "./ContextualMenu.module.scss";
import {
  ContextualMenuEnum,
  ContextualMenuInterface,
} from "./ContextualMenu.interface";
import InternalLink from "./Buttons/InternalLink";

const ContextualMenu: React.FC<ContextualMenuInterface> = ({
  source,
  variant,
  slug,
  podcast,
  duration,
  episodeTime,
  title,
  subtitle,
  artwork,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isShareModalOpen } = useStoreState(UIStore, (store) => store);
  const contextualMenuRef = useRef(null);
  const iconButtonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      contextualMenuRef.current &&
      !contextualMenuRef.current.contains(event.target) &&
      !iconButtonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isOpen && !isShareModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [contextualMenuRef, isOpen, isShareModalOpen]);

  return (
    <>
      <IconButton
        className={classnames(styles.iconButton, styles[variant], className)}
        onClick={toggleMenu}
        ref={iconButtonRef}
      >
        {variant === ContextualMenuEnum.Feed ? <MoreSmallIcon /> : <MoreIcon />}
      </IconButton>
      {isOpen && (
        <div
          className={classnames(styles.container, styles[variant])}
          ref={contextualMenuRef}
        >
          {process.env.NEXT_PUBLIC_IS_CONTEXT_MENU === "true" && (
            <Listened
              episodeSlug={slug}
              duration={duration}
              episodeTime={episodeTime}
              variant={variant}
            />
          )}
          <Bookmark source={source} episodeSlug={slug} variant={variant} />
          <Share slug={slug} title={title} subtitle={subtitle} artwork={artwork}/>
          <InternalLink slug={podcast.slug} variant="podcast" />
          <InternalLink slug={slug} variant="episode" />
        </div>
      )}
    </>
  );
};

export default ContextualMenu;
